//Production
// export const API_URL = 'https://app.findpetapp.com/v2/';
// export const PUBLIC_URL = 'https://app.findpetapp.com/';
// export const FICHAS_URL = 'https://fichas.findpetapp.com/';
// export const CARNET_URL = 'https://app.findpetapp.com/v2/ruf/';
// export const MP_CLIENT = "APP_USR-6a1c125b-0d88-455e-b225-6a6b320f7a29";

//Development
export const API_URL = "https://dev.findpetapp.com/v2/";
export const PUBLIC_URL = "https://dev.findpetapp.com/";
export const FICHAS_URL = "https://fichas.findpetapp.com/";
export const CARNET_URL = "https://dev.findpetapp.com/v2/ruf/";
// export const MP_CLIENT = "APP_USR-06e24f14-d68f-47bd-a6d1-d3a78e1ed9b2";
export const MP_CLIENT = "APP_USR-a1b785a9-90df-47da-bc9e-2a4054874a5f";
